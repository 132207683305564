import Cookies from 'js-cookie';

/**
    * @function setUserSession
    * @params token, user
    * @description used to stores the user's token and information in cookies for 14 days
*/
export const setUserSession = (token, user) => {
    Cookies.set('adu', JSON.stringify(user), {
        expires: 14
    });
    Cookies.set('adact', token, {
        expires: 14
    });
}

/**
    * @function setCookieToken
    * @params token
    * @description used to set a cookie named 'adact' with a specified token that expires in 14 days
*/
export const setCookieToken = (token) => {
    Cookies.set('adact', token, {
        expires: 14
    });
}

/**
    * @function setCookieTheme
    * @params theme
    * @description used to set a cookie named 'adtp' with a specified theme value that expires in 14 days
*/
export const setCookieTheme = (theme) => {
    if (window.location.hostname === "localhost") {
        Cookies.set('adtp', theme, {
            expires: 14
        });
    } else {
        Cookies.set('adtp', theme, {
            expires: 14,
            domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    }
}

/**
    * @function setCheckoutToken
    * @params token
    * @description used to set a cookie named 'adpt' with a specified checkout token value that expires in 5 minutes
*/
export const setCheckoutToken = (token) => {
    if (window.location.hostname === "localhost") {
        Cookies.set('adpt', token, {
            expires: 10 / 1440
        });
    } else {
        Cookies.set('adpt', token, {
            expires: 10 / 1440,
            domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    }
}

/**
    * @function updateUserSession
    * @params user
    * @description used to store user information in a cookie for 14 days
*/
export const updateUserSession = (user) => {
    Cookies.set('adu', JSON.stringify(user), {
        expires: 14
    });
}

/**
    * @function getUser
    * @params
    * @description used to retrieve user information from a cookie and returns it as a JSON object
*/
export const getUser = () => {
    const userStr = Cookies.get('adu');
    if (userStr) return JSON.parse(userStr);
    return null;
}

/**
    * @function getToken
    * @params
    * @description used to retrieve a token from a cookie named 'adact'
*/
export const getToken = () => {
    const token = Cookies.get('adact');
    if (token) return token;
    return null;
}

/**
    * @function getTheme
    * @params
    * @description used to retrieve the theme stored in a cookie named 'adtp'
*/
export const getTheme = () => {
    const theme = Cookies.get('adtp');
    if (theme) return theme;
    return null;
}

/**
    * @function removeUserSession
    * @params
    * @description used to remove specific cookies related to user session
*/
export const removeUserSession = () => {
    Cookies.remove('adact');
    Cookies.remove('adu');
}