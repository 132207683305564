import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";

// Redux-Functions
import { setSignInModal, selectToken } from "../redux/userSlice";

// handle the private routes
const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);

    if (token) {
        return <Outlet>
            {props?.children}
        </Outlet>;
    } else {
        dispatch(setSignInModal(true));
        return <Navigate to="/" />;
    }
}

export default PrivateRoute;

PrivateRoute.prototypes = {
    children: PropTypes.node.isRequired
}